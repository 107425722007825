import "swiper/css";
import "swiper/css/navigation";
import { useSwiper } from "swiper/react";
import styles from "./index.module.scss";

const Btns = () => {
  const swiper = useSwiper();

  return (
    <div className={styles.btns}>
      <button
        disabled={!swiper.allowSlidePrev}
        onClick={() => swiper.slidePrev()}
      >
        ←
      </button>
      <button
        disabled={!swiper.allowSlideNext}
        onClick={() => swiper.slideNext()}
      >
        →
      </button>
    </div>
  );
};

export default Btns;
