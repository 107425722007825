import { Discord, Instagram, logo, Opensea, Twitter } from "../../assets";
import { menu } from "../../constants";
import styles from "./index.module.scss";

const Footer = () => {
  return (
    <footer className={styles["footer"]}>
      <div className="container">
        <div className={styles["footer__wrap"]}>
          <div>
            <h2 className={styles["footer__title"]}>BYOB</h2>
            <h3>Be your own budtender</h3>
          </div>
          <div>
            <h3 className="text-green">Join the Community</h3>
            <div className={styles["footer__social"]}>
              <a
                target="_blank"
                rel="nofollow"
                href="https://twitter.com/DopePlants"
              >
                <Twitter />
              </a>
              <a
                target="_blank"
                rel="nofollow"
                href="https://discord.gg/nPzWpJEbRj"
              >
                <Discord />
              </a>
              <a
                target="_blank"
                rel="nofollow"
                href="https://www.instagram.com/dopeplantsofficial/"
              >
                <Instagram />
              </a>
              <a
                target="_blank"
                rel="nofollow"
                href="#"
              >
                <Opensea />
              </a>
            </div>
            {/* <div className={styles["footer__input-wrap"]}>
              <input
                className={styles["footer__input"]}
                type="text"
                placeholder="Email"
              />
              <button className={styles["footer__input-btn"]}>send</button>
            </div> */}
          </div>
        </div>
        <div className={styles["footer__wrap-menu"]}>
          <img src={logo} alt="Logo" />
          <nav>
            <ul className={styles["footer__menu"]}>
              {menu.map((item, idx) => (
                <li key={idx}>
                  <a className={styles["menu__link"]} href={`#${item}`}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className={styles["footer__copyright"]}>
          <p>DopePlants 2022, All Rights Reserved</p>
          <p>Privacy Policy</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
