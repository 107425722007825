import cn from "classnames";
import { useState } from "react";
import styles from "./collapse.module.scss";

const Collapse = ({ idx = 1, title = "", id, children, faq }) => {
  const [collapse, setCollapse] = useState({ id: null, status: false });

  const handleCollapse = (id) => {
    setCollapse({
      id,
      ...(id !== collapse.id
        ? { id, status: true }
        : { status: !collapse.status }),
    });
  };
  return (
    <div
      key={idx}
      onClick={() => handleCollapse(idx)}
      className={cn(styles["collapse"], {
        [styles["collapse_active"]]: collapse.id === idx && collapse.status,
        [styles["collapse_faq"]]: faq,
      })}
    >
      <h3
        className={cn(styles["collapse--title"], {
          [styles["collapse--title_faq"]]: faq,
        })}
      >
        {faq && <div className={styles["collapse--icon"]}>↑</div>}
        <span className={styles["collapse--title_wrap"]}>
          <span className={styles["collapse--id"]}>{id}</span> {title}
        </span>
        {!faq && <div className={styles["collapse--icon"]}>↑</div>}
      </h3>
      <div
        className={cn(styles["collapse--desc"], {
          [styles["collapse--desc_active"]]:
            collapse.id === idx && collapse.status,
            [styles["collapse--desc_faq"]]: faq,
        })}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </div>
  );
};

export default Collapse;
