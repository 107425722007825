import { BrowserRouter as Router } from 'react-router-dom'
import { Route, Routes } from 'react-router'
import './styles/index.scss'
import Home from "./pages/Home"
import { PATHS } from './constants';


function App() {
  return (
    <Router>
      <Routes>
        <Route path={PATHS.home} element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
