import { slider1, slider2, slider3, slider4, slider5, slider6 } from "./assets";

export const PATHS = {
  home: "/",
  roadmap: "roadmap",
};

export const EMAILJS = {
  publicKey: "OgCgR4G8sG-GbmwQE",
  serviceId: "service_52pv4t3",
  templateId: "template_0igm6dn",
};

export const menu = [
  "home",
  "collection",
  "about",
  "partners",
  "roadmap",
  "team",
  "faq",
];

export const PRICE = 0.75;

export const sliders = [
  {
    id: 1,
    img: slider1,
    name: "Farmer",
    text: "Cannabis and hemp cultivation has revived multigenerational farms like ours, introducing new opportunity to rural America.",
  },
  {
    id: 2,
    img: slider2,
    name: "Scientist",
    text: "The ability to research Cannabis has already unlocked opioid free treatments for pain, insomnia and mental health.",
  },
  {
    id: 3,
    img: slider3,
    name: "Convict",
    text: "A minor cannabis infraction cost me years, now I share my story supporting social justice reform.",
  },
  {
    id: 4,
    img: slider4,
    name: "Veteran",
    text: "Access to medical cannabis made my PTSD manageable, now I enjoy the freedom I fought for.",
  },
  {
    id: 5,
    img: slider5,
    name: "Athlete",
    text: "As an athlete plant derived wellness methods for training and recovery have been a game changer!",
  },
  {
    id: 6,
    img: slider6,
    name: "Businessman",
    text: "Recreational & medicinal cannabis is a rapidly evolving global industry and regulatory environment with limitless opportunities.",
  },
];

export const roadmaps = [
  {
    id: 1,
    title: "The Germination State",
    text: `
    <ul>
    <li>After reveal, there will be an immediate competition</li>
    <li>To enter, you’ll need to possess the Golden Goat Strain</li>
    <li>Once announced, you’ll have 48 hours to enter the competition to win 10 ETH</li>
    <li>To participate, verify your address and then you’ll be in the private channel dedicated to this competition</li>
    <li>The rules for the competition will be announced within the channel, good luck!</li>
    </ul>
    <div>
    <hr />
    </div>
    <p>Benefiting our holders by bridging the gap between the Cannabis industry and the Web3 space. </p>
    <p>Holding a DopePlant will allow you to purchase cannabis related products at a discount, whether you're in-store, or ordering for delivery depending on state laws…. And that's just the beginning</p>
    `,
  },
  {
    id: 2,
    title: "The Seedling period",
    text: `
    <ul>
    <li>We will be acquiring bitcoin miners after mint to guarantee our farm is always funded and to ensure longevity for the project. </li> 
    <li>The yield earned by the bitcoin miners will go into a public wallet to fund operations, while also doing weekly giveaways to standout community members. </li> 
    <li>Farming bitcoin allows us to simultaneously grow and sustain our Web2 and Web3 audiences. </li>
    <li>You'll not only benefit from the yield the Bitcoin miners produce, but also from exclusive clothing drops.</li>
    <li>This will be a unique opportunity to show your proof of ownership through the blockchain and in a physical way by receiving full commercial usage rights as a holder of this NFT.</li>
    </ul>
  `,
  },
  {
    id: 3,
    title: "The Vegetative Phase",
    text: `
    <ul>
    <li>Some of you may know the saying ”Your network often times is your net-worth,” and our team truly believes in that statement. </li>
    <li>To bring our like-minded community members together, we will be hosting events in cities of the community’s selection that will give members a chance to spark new connections. </li>
    <li>Whether we invite cannabis entrepreneurs or Web3 pioneers, these events will only familiarize yourself within these emerging industries. </li>
    <li>Working alongside our partners, we’ll be including our community members in the most prominent cannabis expos globally. </li>
    `,
  },
  {
    id: 4,
    title: "Flowering Time (Our endless next step)",
    text: `
    <ul>
    <li>This step will be an ever-evolving step that won’t stop progressing. </li>
    <li>Using revenue from the project and reinvesting it into tangible assets that will benefit holders forever. </li>
    <li>Taking action on the legal side to get DopePlants even more involved in the cannabis industry through partnerships and connections. </li>
    </ul>
    `,
  },
];

export const faq = [
  {
    id: 1,
    title: "Why join DopePlants?",
    text: `
  Well, you tell me? Are you here for fascinating and realistic art? Or, to be a part of a digitalized movement into the powerful cannabis industry that allows you to redeem physical assets for your engagement, participation and potentially your growing skills. Whether it’s clothing, currencies or maybe some of your own weed, we are striving to acquire the cannabis industry through your computer and IRL.
  `,
  },
  {
    id: 2,
    title: "What’s so special about DopePlants?",
    text: `
  As you may know, it’s almost impossible to get high through technology, which is unfortunate. So, we plan on taking this digitalized world towards tangible items. Yes, we’re talking about real weed. As we intend on creating cannabis-based utilities digitally, we also plan on making them physical. You can understand where this is going, so please, just wait and chances are you’ll be getting really high with us, if you’d like…
  `,
  },
  {
    id: 3,
    title: "Where can DopePlants be found?",
    text: `
    Before mint you won't be able to find DopePlants anywhere, in the near future if you’re lucky enough, you’ll be able to mint yourself a DopePlant through our website (Dopeplants.io.) Don’t worry, if you’re unable to snag one during mint, DopePlants will be available on the secondary markets, OpenSea, X2Y2, LooksRare, Coinbase NFT and others. If you didn’t get the chance to join our exclusive-access mint, you’ll still be able to join the team. Trust us, there’ll be numerous ways to get involved with DopePlants, as we are just lighting up.
  `,
  },
  {
    id: 4,
    title: "How many are in the collection?",
    text: `
    TBD
    `,
  },
  {
    id: 5,
    title: "What else should I know that comes along with holding DopePlants?",
    text: `
    We’re working alongside our partners to provide benefits to our holders. DopePlants is bridging the gap between cannabis and the Web3 world. This is your time to get introduced into our community and become a holder. Whether it's benefiting through Web3, or real-life assets, this is a project that’ll allow you to benefit physically and digitally for a lifetime.
    `,
  },
  {
    id: 6,
    title: "Who is behind DopePlants?",
    text: `
    Behind DopePlants is a team of blockchain advocates, Crypto enthusiasts, and cannabis advocates. We’ve been very involved in the crypto/NFT space for a while trading and selling from the user side and we’ve all grown to love blockchain technology as true supporters for the evolving industry. While web3 momentum continues to thrive, we’ve noticed a lack of cannabis industry representation in the NFT space. We saw this as an amazing opportunity to bring together two emerging areas we are passionate about to develop an inclusive community of cannabis indulging, crypto loving, growth minded people.
    `,
  },
];
