import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { sliders } from "../../constants";
import Btns from "./btns";
import styles from "./index.module.scss";

const Slider = () => {
  return (
    <Swiper
      className={styles.slider}
      spaceBetween={32}
      slidesPerView={1}
      modules={[Navigation]}
      centeredSlides
      initialSlide={3}
      loop={true}
      breakpoints={{
        567: {
          slidesPerView: 3,
        },
      }}
    >
      <Btns />
      {sliders.map((item) => (
        <SwiperSlide key={item.id}>
          <div className={styles['slider__wrap']}>
            <div className={styles["slider__img"]}>
              <img src={item.img} alt={item.name} />
            </div>
            <div className={styles['slider__desc']}>
              <h4>{item.name}</h4>
              <p>{item.text}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
