import { useEffect, useState } from "react";
import {
  about,
  culture,
  Discord,
  imgMeet,
  Instagram,
  Opensea,
  Twitter,
} from "../../assets";
import Collapse from "../../components/Collapse";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Mint from "../../components/Mint";
import Modal from "../../components/Modal";
import Slider from "../../components/Slider";
import { faq, roadmaps } from "../../constants";
import styles from "./home.module.scss";

const Home = () => {
  const [openModal, setIsOpenModal] = useState(false);
  const closeModal = () => setIsOpenModal(false);

  useEffect(() => {
    const time = setTimeout(() => {
      setIsOpenModal(true);
    }, 100);
    return () => {
      clearTimeout(time);
    };
  }, []);

  return (
    <>
      <section id="home" className={styles["intro"]}>
        <Header />
        <div className="container">
          <div className={styles["block"]}>
            <h1>DopePlants</h1>
            <h2 className={styles["block__sub-title"]}>NFT Collection</h2>
            <p className={styles["block__text"]}>
              Join the budding NFT community revolutionizing <br /> the cannabis
              industry’s emergence into the Web3 space.
            </p>
            {/* <Timer /> */}
            <div className={styles["social"]}>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://twitter.com/DopePlants"
              >
                <Twitter />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://discord.gg/nPzWpJEbRj"
              >
                <Discord />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/dopeplantsofficial/"
              >
                <Instagram />
              </a>
              <a rel="noreferrer" target="_blank" href="#">
                <Opensea />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <Mint /> */}
      <section id="collection" className={styles["dopeplants"]}>
        <div className={styles["dopeplants__desc"]}>
          <div className="container">
            <div className={styles["dopeplants__wrap-slider"]}>
              <p>
                DopePlants are a collection of unique cannabis empowerment NFTs
                built on the Ethereum blockchain to cultivate Web3 and IRL
                opportunities for our exclusive member community that we call,
                our farm.
              </p>
              <Slider />
            </div>
          </div>
        </div>
        <div className="container">
          <div className={styles["dopeplants__wrap"]}>
            <div className={styles["dopeplants__block"]}>
              <h2 className={styles["dopeplants__title"]}>The DopePlants</h2>
              <p>
                Your DopePlant represents your all access, backstage pass to a
                budding community of cannabis enthusiasts, Web3 advocates, and
                NFT loving individuals. Chill vibes only please.
              </p>
            </div>
            <div className={styles["dopeplants__block"]}>
              <p>
                Be part of the first Cannabis advocates to join our discord to claim your pre-mint DopeList spot!
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://discord.gg/nPzWpJEbRj"
                className="btn"
              >
                <Discord />
                join our discord
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className={styles["about"]}>
        <div className="container">
          <div className={styles["about__wrap"]}>
            <img className={styles["about__img"]} src={about} alt="About" />
            <div>
              <h2 className={styles["about__title"]}>About Us</h2>
              <div className={styles["about__text"]}>
                <p>
                  A DopePlants NFT goes beyond a cool piece of digital art… it
                  gives you access to physical goods, IRL events, direct support
                  of cannabis industry causes and collabs, and much more.
                </p>
                <p>
                  The cannabis industry and Web3 community are industry
                  disruptors emerging into the mainstream market in parallel. We
                  are building the NFT community best positioned to
                  revolutionize the cannabis industry’s entry point into the
                  metaverse.
                </p>
                <p>Join the Advocates</p>
              </div>
              <div className={styles["about__btns"]}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://discord.gg/nPzWpJEbRj"
                  className="btn"
                >
                  <Discord /> Discord
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/DopePlants"
                  className="btn-primary"
                >
                  <Twitter /> Twitter
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="partners" className={styles["partners"]}>
        <div className="container">
          <h2>Partners</h2>
          <p className={styles["partners__desc"]}>
            We couldn’t deliver tangible value through digital art without the
            support of our cannabis partners & Web3 Collaborators
          </p>
          <div className={styles["partners__wrap"]}>
            <div className={styles["partners__block"]}>
              <div className={styles["partners__img"]}>
                <img src={culture} alt="Culture and Cannabislv" />
              </div>
              <a
                target="_blank"
                className={`btn ${styles["partners__btn"]}`}
                href="https://www.cultureandcannabislv.com/"
                rel="noreferrer"
              >
                cultureandcannabislv.com
              </a>
            </div>
            <div className={styles["partners__block"]}>
              <div className={styles["partners__img"]}>Coming Soon</div>
            </div>
            <div className={styles["partners__block"]}>
              <div className={styles["partners__img"]}>Coming Soon</div>
            </div>
          </div>
        </div>
      </section>
      <section id="roadmap" className={styles.roadmap}>
        <div className="container">
          <div className={styles["roadmap__block"]}>
            <div>
              <h2 className={styles["roadmap__title"]}>Roadmap</h2>
              <h2 className={styles["roadmap__soon"]}>Coming Soon</h2>
              {/* <p className={styles["roadmap__desc"]}>
                Join the budding NFT community revolutionizing the cannabis
                industry’s emergence into the Web3 space.
              </p>
              <div className={styles["roadmap__wrap"]}>
                {roadmaps.map((item) => (
                  <Collapse key={item.id} id={item.id} title={item.title}>
                    {item.text}
                  </Collapse>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section id="meet" className={styles["meet"]}>
        <div id="team" className={styles["meet__bg"]}>
          <div className="container">
            <div className={styles["meet__wrap"]}>
              <img
                className={styles["meet__img"]}
                height={400}
                width={400}
                src={imgMeet}
                alt="Meet"
              />
              <div className={styles["meet-block"]}>
                <h2 className={styles["meet__title"]}>Meet the Budtenders</h2>
                <p className={styles["meet-block__text"]}>
                  Behind DopePlants is a team of blockchain advocates, Crypto
                  enthusiasts, and cannabis advocates. We’ve been very involved
                  in the crypto/NFT space for a while trading and selling from
                  the user side and we’ve all grown to love blockchain
                  technology as true advocates for the evolving industry. While
                  Web3 momentum continues to thrive, we’ve noticed a lack of
                  cannabis industry representation in the NFT space. We saw this
                  as an amazing opportunity to bring together two emerging areas
                  we are passionate about to develop an inclusive community of
                  cannabis indulging, crypto loving, growth minded people.{" "}
                </p>
                <h3 className={styles["meet-block__join"]}>
                  Join the Advocates
                </h3>
                <div className={styles["meet-block__btns"]}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://discord.gg/nPzWpJEbRj"
                    className="btn"
                  >
                    <Discord /> Discord
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/DopePlants"
                    className="btn-primary"
                  >
                    <Twitter /> Twitter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" className={styles["faq"]}>
        <div className="container">
          <h2>FAQ</h2>
          <div className={styles["faq__block"]}>
            {faq.map((item) => (
              <Collapse faq key={item.id} title={item.title}>
                {item.text}
              </Collapse>
            ))}
          </div>
        </div>
      </section>
      <Footer />
      <Modal isOpen={openModal} closeModal={closeModal} />
    </>
  );
};

export default Home;
