import ReactModal from "react-modal";
import Form from "../Form";
import styles from "./index.module.scss";

const Modal = ({ isOpen, closeModal }) => {
  return (
    <ReactModal
      className={styles["modal"]}
      overlayClassName={styles["overlay"]}
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className={styles["modal__header"]}>
        <h2>
          Join the DopeList for a chance to grow with us!
        </h2>
        <button className={styles["modal__close-btn"]} onClick={closeModal}>
          &times;
        </button>
      </div>
      <div className={styles["modal__content"]}>
        <Form />
      </div>
    </ReactModal>
  );
};

export default Modal;
